import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.png";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import "../../assets/css/login.css";
import { Container, Button, Form, FloatingLabel } from "react-bootstrap";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import CryptoJS from "crypto-js";

const EditKey = () => {
    const [username, setUsername] = useState("");
    const [platform, setPlatform] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { id } = useParams();  // Document ID from the URL
    const secretKey = "$26102024$";
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchData = async () => {
            if (!id) {
                setError("Document ID is missing.");
                return;
            }

            try {
                // Construct the document path and fetch it
                const docRef = doc(db, "keys", id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    console.log("Document data:", data);

                    // Check if uid matches the current user's uid
                    const currentUid = localStorage.getItem("uid");
                    if (data.uid === currentUid) {
                        // Extract fields from data
                        const decryptedPassword = CryptoJS.AES.decrypt(data.password, secretKey).toString(CryptoJS.enc.Utf8) || "";
                        setUsername(data.username || "");
                        setPlatform(data.platform || "");
                        setPassword(decryptedPassword); // Set decrypted password
                    } else {
                        setError("You do not have permission to edit this key.");
                    }
                } else {
                    console.log("Document does not exist!");
                    setError("Document not found!");
                }
            } catch (err) {
                console.error("Error fetching document:", err);
                setError("Error fetching document: " + err.message);
            }
        };

        fetchData();
    }, [id]);

    const handleForm = async (event) => {
        event.preventDefault();
        console.log("Form submitted");
        console.log("User Name:", username);
        console.log("Platform:", platform);
        console.log("Password:", password);

        try {
            const docRef = doc(db, "keys", id); // Reference to the document
            // Encrypt the new password before updating
            const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
            
            // Update the document with new values
            await updateDoc(docRef, {
                username: username,
                platform: platform,
                password: encryptedPassword, // Update with encrypted password
            });

            // Redirect to the dashboard after successful update
            navigate(`/dashboard/${localStorage.getItem("uid")}`);
        } catch (err) {
            console.error("Error updating document:", err);
            setError("Error updating document: " + err.message);
        }
    };

    return (
        <main>
            <Container className="container-center">
                <div className="form-container">
                    <img src={Logo} alt="PassKey" />
                    <form onSubmit={handleForm} autoComplete="off">
                        {error && <p style={{ color: "red" }}>{error}</p>}
                        <FloatingLabel
                            controlId="floatingInputUsername"
                            label="User Name"
                            className="mb-3"
                        >
                            <Form.Control
                                name="text"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                                type="text"
                                placeholder="John Doe"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInputPlatform"
                            label="Platform"
                            className="mb-3"
                        >
                            <Form.Control
                                name="platform"
                                value={platform}
                                onChange={(event) => setPlatform(event.target.value)}
                                type="text"
                                placeholder="Google"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInputPassword"
                            label="Password"
                            className="mb-3"
                        >
                            <Form.Control
                                name="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                type="password"
                                placeholder="JohnDoe@123"
                            />
                        </FloatingLabel>
                        <Button type="submit" className="w-100 mb-3">Edit Key</Button>
                    </form>
                </div>
            </Container>
        </main>
    );
};

export default EditKey;
