import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.png";
import "../../assets/css/login.css";
import { Container, Button, Form, FloatingLabel, Alert } from "react-bootstrap";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged, setPersistence, browserLocalPersistence, sendPasswordResetEmail } from "firebase/auth";
import { redirect } from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSigningIn, setIsSigningIn] = useState(localStorage.getItem("signIn"));
    const [errorMessage, setErrorMessage] = useState("");
    const [uid, setUid] = useState("");
    const [resetStatus, setResetStatus] = useState(""); // State to manage reset status messages

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUid(user.uid);
                window.location.href = `/dashboard/${user.uid}`;
            }
        });
        return () => unsubscribe();
    }, []);

    const handleForm = async (event) => {
        event.preventDefault();
        try {
            await setPersistence(auth, browserLocalPersistence);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            setUid(user.uid);
            setIsSigningIn(true);
            localStorage.setItem("signin", true);
            localStorage.setItem("email", email);
            localStorage.setItem("uid", user.uid);
            window.location.href = `/dashboard/${user.uid}`;
        } catch (error) {
            setErrorMessage(error.message);
            setIsSigningIn(false);
            localStorage.setItem("signin", false);
        }
    };

    // Handle password reset
    const handlePasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            setResetStatus("Password reset email sent. Check your inbox.");
        } catch (error) {
            setResetStatus("Failed to send password reset email. Please try again.");
            console.error("Error sending password reset email:", error);
        }
    };

    if (isSigningIn) {
        return redirect(`/dashboard/${localStorage.getItem("uid")}`);
    }

    return (
        <main>
            <Container className="container-center">
                <div className="form-container">
                    <img src={Logo} alt="PassKey" />
                    {errorMessage && (
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                    )}
                    {resetStatus && <Alert variant="info">{resetStatus}</Alert>}

                    <form onSubmit={handleForm} autoComplete="off">
                        <FloatingLabel controlId="floatingInputEmail" label="Email address" className="mb-3">
                            <Form.Control 
                                name="email" 
                                onChange={(event) => setEmail(event.target.value)} 
                                type="email" 
                                placeholder="JohnDoe@gmail.com" 
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingInputPassword" label="Password" className="mb-3">
                            <Form.Control 
                                name="password" 
                                onChange={(event) => setPassword(event.target.value)} 
                                type="password" 
                                placeholder="JohnDoe@123" 
                            />
                        </FloatingLabel>
                        <div className="mb-3">
                            <span><a href="#" onClick={handlePasswordReset}>Forgot Password?</a></span>
                            {/* <a href="#" onClick={handlePasswordReset}>Forgot Password?</a> */}
                        </div>
                        <Button type="submit" className="w-100 mb-3">Login</Button>
                        <div className="mb-3">
                            <span>Didn't Create an Account? <a href="/signup">Sign Up</a></span>
                        </div>
                    </form>
                </div>
            </Container>
        </main>
    );
};

export default Login;
