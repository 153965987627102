// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBM5zFO0cMKJqkhaDRtkMsQtzD5HQoCzrc",
  authDomain: "passkey-d7b1a.firebaseapp.com",
  projectId: "passkey-d7b1a",
  storageBucket: "passkey-d7b1a.appspot.com",
  messagingSenderId: "701703915104",
  appId: "1:701703915104:web:bd1fc64ca1e583a8bca505"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app)

export { app, auth, db }