import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.png";
import "../../assets/css/login.css";
import { Container, Button, Form, FloatingLabel, Alert } from "react-bootstrap";
import { onAuthStateChanged } from "firebase/auth";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { auth } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import CryptoJS from "crypto-js";

const Addkey = () => {
    const [username, setUsername] = useState("");
    const [platform, setPlatform] = useState("");
    const { id } = useParams();
    const [uid, setUid] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");  // State for error message
    const secretKey = "$26102024$";

    useEffect(() => {
        // Check if the user is already authenticated on page load
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUid(user.uid);
                if (id === user.uid) {
                    console.log("Welcome");
                } else {
                    window.location.href = "/";
                }
            } else {
                window.location.href = "/";
            }
        });

        return () => unsubscribe();
    }, [id]);

    const handleForm = async (event) => {
        event.preventDefault();

        if (!username || !platform || !password) {
            setError("All fields are required.");
            return;
        }

        // Encrypt the password before saving
        const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();

        try {
            // Add document to Firestore with encrypted password
            const docRef = await addDoc(collection(db, "keys"), {
                platform: platform,
                username: username,
                password: encryptedPassword,
                uid: uid
            });

            // Show document ID and clear form
            console.log(`Key added with ID: ${docRef.id}`);
            setUsername("");
            setPlatform("");
            setPassword("");
            setError("");  // Clear error message if successful

            window.location.href = `/dashboard/${uid}`;
        } catch (error) {
            setError(`Error adding key: ${error.message}`);
            console.log(`Error adding key: ${error.message}`);
        }
    };

    return (
        <main>
            <Container className="container-center">
                <div className="form-container">
                    <img src={Logo} alt="PassKey" />
                    {error && (
                        <Alert variant="danger" onClose={() => setError("")} dismissible>
                            {error}
                        </Alert>
                    )}
                    <form onSubmit={handleForm} autoComplete="off">
                        <FloatingLabel
                            controlId="floatingInputUsername"
                            label="User Name"
                            className="mb-3"
                        >
                            <Form.Control
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                                type="text"
                                placeholder="John Doe"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInputPlatform"
                            label="Platform"
                            className="mb-3"
                        >
                            <Form.Control
                                value={platform}
                                onChange={(event) => setPlatform(event.target.value)}
                                type="text"
                                placeholder="Google"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInputPassword"
                            label="Password"
                            className="mb-3"
                        >
                            <Form.Control
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                type="password"
                                placeholder="JohnDoe@123"
                            />
                        </FloatingLabel>
                        <Button type="submit" className="w-100 mb-3">Add Key</Button>
                    </form>
                </div>
            </Container>
        </main>
    );
};

export default Addkey;
