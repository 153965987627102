// src/App.js

import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login_page/Login.js";
import Signup from "./components/signup_page/signup.js";
import Dashboard from "./components/dashboard/Dashboard.js";
import Addkey from "./components/addKey/Addkey.js";
import EditKey from "./components/editKey/Editkey.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { auth } from "./components/firebase.js";
import { signOut } from "firebase/auth";
import { useParams, useNavigate } from "react-router-dom";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "./components/firebase";
import Profile from "./components/profilePage/Profile.js";

// Correct DeleteKey component
const DeleteKey = () => {
  const { id } = useParams(); // Get the ID from URL params
  const navigate = useNavigate(); // Use useNavigate for programmatic navigation

  useEffect(() => {
    const handleDelete = async () => {
      try {
        const refDoc = doc(db, "keys", id);
        await deleteDoc(refDoc);
        console.log("Deleted Key Id: " + id);
        navigate(`/dashboard/${localStorage.getItem("uid")}`); // Redirect to dashboard
      } catch (error) {
        console.log("Error deleting document: " + error.message); // Provide specific error message
      }
    };

    handleDelete(); // Call the delete function
  }, [id, navigate]); // Dependencies for useEffect

  return null; // Optionally, you could display a loading indicator
};

// Handle logout with a component and redirect
const Logout = () => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
      // After logging out, redirect to the login page
      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return null; // Optionally, you can show a loading or success message here
};

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/dashboard/:id" element={<Dashboard />} />
        <Route exact path="/addkey/:id" element={<Addkey />} />
        <Route exact path="/editKey/:id" element={<EditKey />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/deleteKey/:id" element={<DeleteKey />} /> {/* Add delete route */}
        <Route exact path="/profile" element={<Profile />} />
        {/* For undefined routes, you can redirect to a 404 page or the login page */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
