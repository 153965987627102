import React, { useState, useEffect } from "react";
import "../../assets/css/login.css";
import { Container, Button, Form, FloatingLabel, Spinner, Alert } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import { db, auth } from "../firebase"; // Import Firebase instance and auth
import { doc, getDoc, setDoc } from "firebase/firestore";
import { sendPasswordResetEmail } from "firebase/auth"; // Import password reset function
import { useNavigate } from "react-router-dom";

const Profile = () => {
    const [uid, setUid] = useState(localStorage.getItem("uid"));
    const [email, setEmail] = useState(localStorage.getItem("email")); // Store email from localStorage
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [phNo, setPhNo] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [resetStatus, setResetStatus] = useState(""); // State for reset status message

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            if (uid) {
                try {
                    const userDocRef = doc(db, "profile", uid);
                    const docSnap = await getDoc(userDocRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setFName(data.fName || "");
                        setLName(data.lName || "");
                        setPhNo(data.phNo || "");
                    } else {
                        console.log("No such document!");
                    }
                } catch (err) {
                    setError("Error fetching profile data.");
                    console.error("Error fetching profile data:", err);
                } finally {
                    setLoading(false);
                }
            } else {
                setError("User ID is missing.");
                setLoading(false);
            }
        };

        fetchUserData();
    }, [uid]);

    // Update user data in Firebase on form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess(false);
        setError(null);

        if (uid) {
            try {
                const userDocRef = doc(db, "profile", uid);
                await setDoc(userDocRef, { uid, fName, lName, phNo }, { merge: true });
                setSuccess(true);

                setTimeout(() => navigate("/dashboard"), 2000);
            } catch (error) {
                console.error("Error updating profile:", error);
                setError("Failed to update profile. Please try again.");
            }
        } else {
            setError("User ID is missing.");
        }
    };

    // Handle password reset via email
    const handlePasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            setResetStatus("Password reset email sent. Check your inbox.");
            setTimeout(() => navigate("/dashboard"), 2000);
        } catch (error) {
            setResetStatus("Failed to send password reset email. Please try again.");
            console.error("Error sending password reset email:", error);
        }
    };

    return (
        <main>
            <Container className="container-center">
                <div className="form-container">
                    <img src={Logo} alt="PassKey" />

                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">Profile updated successfully!</Alert>}
                            {resetStatus && <Alert variant="info">{resetStatus}</Alert>}

                            <Form onSubmit={handleSubmit} autoComplete="off">
                                <FloatingLabel controlId="floatingInputFName" label="First Name" className="mb-3">
                                    <Form.Control 
                                        name="fname" 
                                        value={fName} 
                                        onChange={(event) => setFName(event.target.value)} 
                                        type="text" 
                                        placeholder="John" 
                                    />
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingInputLName" label="Last Name" className="mb-3">
                                    <Form.Control 
                                        name="lname" 
                                        value={lName} 
                                        onChange={(event) => setLName(event.target.value)} 
                                        type="text" 
                                        placeholder="Doe" 
                                    />
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingInputPhone" label="Phone Number" className="mb-3">
                                    <Form.Control 
                                        name="number" 
                                        value={phNo} 
                                        onChange={(event) => setPhNo(event.target.value)} 
                                        type="number" 
                                        placeholder="+91 1234567890" 
                                    />
                                </FloatingLabel>
                                <Button type="submit" className="mb-3">Update Profile</Button>
                                <Button onClick={handlePasswordReset} className="mb-3">
                                    Reset Password
                                </Button>
                            </Form>
                        </>
                    )}
                </div>
            </Container>
        </main>
    );
};

export default Profile;
