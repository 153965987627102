import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.png";
import "../../assets/css/login.css";
import { Container, Button, Form, FloatingLabel, Alert } from "react-bootstrap";
import { auth } from "../firebase";
import { createUserWithEmailAndPassword, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");  // New state for confirmation password
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [uid, setUid] = useState("");  // Store user ID

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUid(user.uid);  // Store the user ID
                window.location.href = `/dashboard/${user.uid}`;
            }
        });

        return () => unsubscribe();
    }, []);

    const handleForm = async (event) => {
        event.preventDefault();

        // Check if passwords match
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        try {
            await setPersistence(auth, browserLocalPersistence);
            
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            setUid(user.uid);  // Store the user ID

            console.log(`Registration successful! User ID: ${user.uid}`);
            setIsRegistering(true);
            window.location.href = `/dashboard/${user.uid}`;
            localStorage.setItem("signin", true);
            localStorage.setItem("email", email);
            localStorage.setItem("uid", user.uid); 
        } catch (error) {
            setIsRegistering(false);
            setErrorMessage(error.message);
            console.log(error.message);
        }
    };

    return (
        <main>
            <Container className="container-center">
                <div className="form-container">
                    <img src={Logo} alt="PassKey" />
                    {errorMessage && (
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                    )}
                    <form onSubmit={handleForm} autoComplete="off">
                        <FloatingLabel
                            controlId="floatingInputEmail"
                            label="Email address"
                            className="mb-3"
                        >
                            <Form.Control
                                name="email"
                                onChange={(event) => setEmail(event.target.value)}
                                type="email"
                                placeholder="JohnDoe@gmail.com"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInputPassword"
                            label="Password"
                            className="mb-3"
                        >
                            <Form.Control
                                name="password"
                                onChange={(event) => setPassword(event.target.value)}
                                type="password"
                                placeholder="JohnDoe@123"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInputConfirmPassword"
                            label="Confirm Password"
                            className="mb-3"
                        >
                            <Form.Control
                                name="confirmPassword"
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                type="password"
                                placeholder="Re-enter your password"
                            />
                        </FloatingLabel>
                        <Button type="submit" className="w-100 mb-3">
                            Sign Up
                        </Button>
                        <span className="mb-3">
                            Already have an Account? <a href="/">Login</a>
                        </span>
                    </form>
                </div>
            </Container>
        </main>
    );
};

export default Signup;
