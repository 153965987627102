import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import "../../assets/css/dashboard.css";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase"; // Firebase instance
import { collection, query, where, getDocs } from "firebase/firestore";
import CryptoJS from "crypto-js";  // Correct import

const Dashboard = () => {
  const { id } = useParams();
  const [uid, setUid] = useState("");
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const secretKey = "$26102024$";
  const navigate = useNavigate();  // Use useNavigate for redirection

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
        if (id !== user.uid) {
          navigate("/");  // Redirect using navigate
        }
      } else {
        navigate("/");  // Redirect if not authenticated
      }
    });

    return () => unsubscribe();
  }, [id, navigate]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileQuery = query(collection(db, "profile"), where("uid", "==", uid));
        const profileSnapshot = await getDocs(profileQuery);
        
        if (profileSnapshot.empty) {
          navigate("/profile");  // Redirect if no profile found
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        navigate("/profile");  // Redirect on error
      }
    };

    if (uid) {
      fetchProfile();
    }
  }, [uid, navigate]);

  useEffect(() => {
    const fetchKeys = async () => {
      setLoading(true);
      try {
        const q = query(collection(db, "keys"), where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
        const keysList = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const decryptedPassword = CryptoJS.AES.decrypt(data.password, secretKey).toString(CryptoJS.enc.Utf8);
          keysList.push({ id: doc.id, ...data, password: decryptedPassword, passwordVisible: false });
        });

        setKeys(keysList);
      } catch (error) {
        setError("Error fetching data: " + error.message);
      } finally {
        setLoading(false);  // Ensure loading is set to false after fetching
      }
    };

    if (uid) {
      fetchKeys();
    }
  }, [uid]);

  // Toggle password visibility
  const togglePass = (index) => {
    setKeys((prevDetails) =>
      prevDetails.map((detail, i) =>
        i === index ? { ...detail, passwordVisible: !detail.passwordVisible } : detail
      )
    );
  };

  return (
    <main>
      <nav>
        <div className="left-nav">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="right-nav">
          <ul>
            <li><Link to="/profile">Profile</Link></li>
            <li><Link to={`/addKey/${id}`}>Add Key</Link></li>
            <li><Link to="/logout">Logout</Link></li>
          </ul>
        </div>
      </nav>
      <div className="key-table w-75 py-5">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : (
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>Platform</th>
                <th>Username</th>
                <th>Password</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="scrollable-tbody">
              {keys.map((detail, index) => (
                <tr key={detail.id} className={detail.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{detail.platform}</td>
                  <td>{detail.username}</td>
                  <td>
                    <div className="form-group">
                      <input
                        type={detail.passwordVisible ? "text" : "password"}
                        className="form-control"
                        value={detail.password}
                        readOnly
                      />
                      <i
                        className={`toggle-password fa fa-fw ${detail.passwordVisible ? "fa-eye" : "fa-eye-slash"}`}
                        onClick={() => togglePass(index)}
                      ></i>
                    </div>
                  </td>
                  <td>
                    <button className="btn btn-primary" onClick={() => navigate(`/editKey/${detail.id}`)}>Edit</button>
                    <button className="btn btn-danger ml-2" onClick={() => navigate(`/deleteKey/${detail.id}`)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </main>
  );
};

export default Dashboard;
